.screen_imagination {
  transition: opacity 0.4s ease-in-out;
  width: 3810px;
  height: 1648px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.back-image {
  object-fit: cover;
  object-position: center; }
