.square {
  border: 1px solid #979797; }
  .square .logo-twitter {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .square .logo-instagram {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .square .logo-fade-out {
    animation-name: switchContentSquare;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out; }

@keyframes switchContentSquare {
  0% {
    left: -233px;
    opacity: 1; }
  30%,
  70% {
    left: 0px;
    opacity: 1; }
  100% {
    left: 0px;
    opacity: 0; } }
  .square .small {
    height: 235px;
    width: 235px;
    border: 1px solid #979797;
    overflow: hidden; }
    .square .small img {
      max-width: 234px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      object-fit: cover;
      object-position: center; }
    .square .small--first-position {
      position: absolute;
      left: 940px;
      top: 1175px; }
    .square .small--second-position {
      position: absolute;
      left: 1410px;
      top: 235px; }
    .square .small--third-position {
      position: absolute;
      left: 2350px;
      top: 1175px; }
    .square .small--fourth-position {
      position: absolute;
      left: 2585px;
      top: 940px; }
    .square .small--fifth-position {
      position: absolute;
      left: 3525px;
      top: 940px; }
    .square .small--first-position .item-logo,
    .square .small--first-position .logo-fade-out, .square .small--second-position .item-logo,
    .square .small--second-position .logo-fade-out, .square .small--third-position .item-logo,
    .square .small--third-position .logo-fade-out, .square .small--fourth-position .item-logo,
    .square .small--fourth-position .logo-fade-out, .square .small--fifth-position .item-logo,
    .square .small--fifth-position .logo-fade-out {
      height: 233px;
      width: 233px;
      background-color: #fff;
      position: absolute;
      z-index: 9;
      left: -233px; }
      .square .small--first-position .item-logo .background-item,
      .square .small--first-position .logo-fade-out .background-item, .square .small--second-position .item-logo .background-item,
      .square .small--second-position .logo-fade-out .background-item, .square .small--third-position .item-logo .background-item,
      .square .small--third-position .logo-fade-out .background-item, .square .small--fourth-position .item-logo .background-item,
      .square .small--fourth-position .logo-fade-out .background-item, .square .small--fifth-position .item-logo .background-item,
      .square .small--fifth-position .logo-fade-out .background-item {
        height: 211px;
        width: 211px;
        margin: 12px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 7; }
        .square .small--first-position .item-logo .background-item.miskCollaborate,
        .square .small--first-position .logo-fade-out .background-item.miskCollaborate, .square .small--second-position .item-logo .background-item.miskCollaborate,
        .square .small--second-position .logo-fade-out .background-item.miskCollaborate, .square .small--third-position .item-logo .background-item.miskCollaborate,
        .square .small--third-position .logo-fade-out .background-item.miskCollaborate, .square .small--fourth-position .item-logo .background-item.miskCollaborate,
        .square .small--fourth-position .logo-fade-out .background-item.miskCollaborate, .square .small--fifth-position .item-logo .background-item.miskCollaborate,
        .square .small--fifth-position .logo-fade-out .background-item.miskCollaborate {
          background-color: #9e75f7; }
        .square .small--first-position .item-logo .background-item.askMisk,
        .square .small--first-position .logo-fade-out .background-item.askMisk, .square .small--second-position .item-logo .background-item.askMisk,
        .square .small--second-position .logo-fade-out .background-item.askMisk, .square .small--third-position .item-logo .background-item.askMisk,
        .square .small--third-position .logo-fade-out .background-item.askMisk, .square .small--fourth-position .item-logo .background-item.askMisk,
        .square .small--fourth-position .logo-fade-out .background-item.askMisk, .square .small--fifth-position .item-logo .background-item.askMisk,
        .square .small--fifth-position .logo-fade-out .background-item.askMisk {
          background-color: #ffb500; }
        .square .small--first-position .item-logo .background-item.miskCity,
        .square .small--first-position .logo-fade-out .background-item.miskCity, .square .small--second-position .item-logo .background-item.miskCity,
        .square .small--second-position .logo-fade-out .background-item.miskCity, .square .small--third-position .item-logo .background-item.miskCity,
        .square .small--third-position .logo-fade-out .background-item.miskCity, .square .small--fourth-position .item-logo .background-item.miskCity,
        .square .small--fourth-position .logo-fade-out .background-item.miskCity, .square .small--fifth-position .item-logo .background-item.miskCity,
        .square .small--fifth-position .logo-fade-out .background-item.miskCity {
          background-color: #21b592; }
        .square .small--first-position .item-logo .background-item.default,
        .square .small--first-position .logo-fade-out .background-item.default, .square .small--second-position .item-logo .background-item.default,
        .square .small--second-position .logo-fade-out .background-item.default, .square .small--third-position .item-logo .background-item.default,
        .square .small--third-position .logo-fade-out .background-item.default, .square .small--fourth-position .item-logo .background-item.default,
        .square .small--fourth-position .logo-fade-out .background-item.default, .square .small--fifth-position .item-logo .background-item.default,
        .square .small--fifth-position .logo-fade-out .background-item.default {
          background-color: #000; }
        .square .small--first-position .item-logo .background-item img,
        .square .small--first-position .logo-fade-out .background-item img, .square .small--second-position .item-logo .background-item img,
        .square .small--second-position .logo-fade-out .background-item img, .square .small--third-position .item-logo .background-item img,
        .square .small--third-position .logo-fade-out .background-item img, .square .small--fourth-position .item-logo .background-item img,
        .square .small--fourth-position .logo-fade-out .background-item img, .square .small--fifth-position .item-logo .background-item img,
        .square .small--fifth-position .logo-fade-out .background-item img {
          position: relative;
          width: 91px;
          object-fit: cover;
          object-position: center;
          z-index: 1;
          object-fit: cover;
          object-position: center; }
    .square .small--first-position .in-square-text-twt, .square .small--second-position .in-square-text-twt, .square .small--third-position .in-square-text-twt, .square .small--fourth-position .in-square-text-twt, .square .small--fifth-position .in-square-text-twt {
      height: 235px;
      width: 235px;
      padding: 12px;
      position: relative; }
      .square .small--first-position .in-square-text-twt .container-text, .square .small--second-position .in-square-text-twt .container-text, .square .small--third-position .in-square-text-twt .container-text, .square .small--fourth-position .in-square-text-twt .container-text, .square .small--fifth-position .in-square-text-twt .container-text {
        position: relative;
        width: 211px;
        height: 181px;
        border-radius: 4px;
        background-color: #da1884;
        padding: 9px 11px 4px 12px; }
        .square .small--first-position .in-square-text-twt .container-text p, .square .small--second-position .in-square-text-twt .container-text p, .square .small--third-position .in-square-text-twt .container-text p, .square .small--fourth-position .in-square-text-twt .container-text p, .square .small--fifth-position .in-square-text-twt .container-text p {
          height: 134px;
          width: 176px;
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 21px;
          letter-spacing: 0;
          line-height: 21px;
          text-align: left;
          word-break: break-word;
          text-overflow: ellipsis;
          text-align: left;
          overflow: hidden; }
        .square .small--first-position .in-square-text-twt .container-text p.textMin, .square .small--second-position .in-square-text-twt .container-text p.textMin, .square .small--third-position .in-square-text-twt .container-text p.textMin, .square .small--fourth-position .in-square-text-twt .container-text p.textMin, .square .small--fifth-position .in-square-text-twt .container-text p.textMin {
          font-size: 21px;
          line-height: 21px; }
        .square .small--first-position .in-square-text-twt .container-text p.textMin100, .square .small--second-position .in-square-text-twt .container-text p.textMin100, .square .small--third-position .in-square-text-twt .container-text p.textMin100, .square .small--fourth-position .in-square-text-twt .container-text p.textMin100, .square .small--fifth-position .in-square-text-twt .container-text p.textMin100 {
          font-size: 18px;
          line-height: 18px; }
        .square .small--first-position .in-square-text-twt .container-text p.textMin200, .square .small--second-position .in-square-text-twt .container-text p.textMin200, .square .small--third-position .in-square-text-twt .container-text p.textMin200, .square .small--fourth-position .in-square-text-twt .container-text p.textMin200, .square .small--fifth-position .in-square-text-twt .container-text p.textMin200 {
          font-size: 14px;
          line-height: 14px; }
        .square .small--first-position .in-square-text-twt .container-text p.textMin300, .square .small--second-position .in-square-text-twt .container-text p.textMin300, .square .small--third-position .in-square-text-twt .container-text p.textMin300, .square .small--fourth-position .in-square-text-twt .container-text p.textMin300, .square .small--fifth-position .in-square-text-twt .container-text p.textMin300 {
          font-size: 12px;
          line-height: 13px; }
        .square .small--first-position .in-square-text-twt .container-text p.more300, .square .small--second-position .in-square-text-twt .container-text p.more300, .square .small--third-position .in-square-text-twt .container-text p.more300, .square .small--fourth-position .in-square-text-twt .container-text p.more300, .square .small--fifth-position .in-square-text-twt .container-text p.more300 {
          font-size: 10px; }
        .square .small--first-position .in-square-text-twt .container-text p.ltr-layout, .square .small--second-position .in-square-text-twt .container-text p.ltr-layout, .square .small--third-position .in-square-text-twt .container-text p.ltr-layout, .square .small--fourth-position .in-square-text-twt .container-text p.ltr-layout, .square .small--fifth-position .in-square-text-twt .container-text p.ltr-layout {
          text-align: left; }
        .square .small--first-position .in-square-text-twt .container-text p.rtl-layout, .square .small--second-position .in-square-text-twt .container-text p.rtl-layout, .square .small--third-position .in-square-text-twt .container-text p.rtl-layout, .square .small--fourth-position .in-square-text-twt .container-text p.rtl-layout, .square .small--fifth-position .in-square-text-twt .container-text p.rtl-layout {
          text-align: right; }
        .square .small--first-position .in-square-text-twt .container-text .logo, .square .small--second-position .in-square-text-twt .container-text .logo, .square .small--third-position .in-square-text-twt .container-text .logo, .square .small--fourth-position .in-square-text-twt .container-text .logo, .square .small--fifth-position .in-square-text-twt .container-text .logo {
          position: absolute;
          left: 184px;
          top: 10px;
          z-index: 0; }
        .square .small--first-position .in-square-text-twt .container-text .avatar, .square .small--second-position .in-square-text-twt .container-text .avatar, .square .small--third-position .in-square-text-twt .container-text .avatar, .square .small--fourth-position .in-square-text-twt .container-text .avatar, .square .small--fifth-position .in-square-text-twt .container-text .avatar {
          position: absolute;
          top: 166px;
          left: 0px;
          height: 44px;
          width: 50px;
          background-color: #ffffff; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .avatar-img, .square .small--second-position .in-square-text-twt .container-text .avatar .avatar-img, .square .small--third-position .in-square-text-twt .container-text .avatar .avatar-img, .square .small--fourth-position .in-square-text-twt .container-text .avatar .avatar-img, .square .small--fifth-position .in-square-text-twt .container-text .avatar .avatar-img {
            position: absolute;
            left: 5px;
            top: 6px;
            height: 35px;
            width: 35px;
            z-index: 6;
            border-radius: 50%; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .triangle, .square .small--second-position .in-square-text-twt .container-text .avatar .triangle, .square .small--third-position .in-square-text-twt .container-text .avatar .triangle, .square .small--fourth-position .in-square-text-twt .container-text .avatar .triangle, .square .small--fifth-position .in-square-text-twt .container-text .avatar .triangle {
            position: absolute;
            top: 0px;
            border-top: 14px solid #da1884;
            border-left: 10px solid #fff;
            border-right: 10px solid #fff;
            left: 30px;
            width: 0;
            margin: 0; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .triangle.miskCollaborate, .square .small--second-position .in-square-text-twt .container-text .avatar .triangle.miskCollaborate, .square .small--third-position .in-square-text-twt .container-text .avatar .triangle.miskCollaborate, .square .small--fourth-position .in-square-text-twt .container-text .avatar .triangle.miskCollaborate, .square .small--fifth-position .in-square-text-twt .container-text .avatar .triangle.miskCollaborate {
            border-top: 14px solid #9e75f7; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .triangle.askMisk, .square .small--second-position .in-square-text-twt .container-text .avatar .triangle.askMisk, .square .small--third-position .in-square-text-twt .container-text .avatar .triangle.askMisk, .square .small--fourth-position .in-square-text-twt .container-text .avatar .triangle.askMisk, .square .small--fifth-position .in-square-text-twt .container-text .avatar .triangle.askMisk {
            border-top: 14px solid #ffb500; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .triangle.miskCity, .square .small--second-position .in-square-text-twt .container-text .avatar .triangle.miskCity, .square .small--third-position .in-square-text-twt .container-text .avatar .triangle.miskCity, .square .small--fourth-position .in-square-text-twt .container-text .avatar .triangle.miskCity, .square .small--fifth-position .in-square-text-twt .container-text .avatar .triangle.miskCity {
            border-top: 14px solid #21b592; }
          .square .small--first-position .in-square-text-twt .container-text .avatar .triangle.default, .square .small--second-position .in-square-text-twt .container-text .avatar .triangle.default, .square .small--third-position .in-square-text-twt .container-text .avatar .triangle.default, .square .small--fourth-position .in-square-text-twt .container-text .avatar .triangle.default, .square .small--fifth-position .in-square-text-twt .container-text .avatar .triangle.default {
            border-top: 14px solid #000; }
        .square .small--first-position .in-square-text-twt .container-text .textname, .square .small--second-position .in-square-text-twt .container-text .textname, .square .small--third-position .in-square-text-twt .container-text .textname, .square .small--fourth-position .in-square-text-twt .container-text .textname, .square .small--fifth-position .in-square-text-twt .container-text .textname {
          color: #ffffff;
          font-family: "Conv_BuenosAires-Black";
          font-size: 9px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 13px;
          height: 16px;
          width: 104px;
          background-color: #000000;
          position: absolute;
          top: 165px;
          left: 50px;
          padding-top: 1px; }
        .square .small--first-position .in-square-text-twt .container-text .time, .square .small--second-position .in-square-text-twt .container-text .time, .square .small--third-position .in-square-text-twt .container-text .time, .square .small--fourth-position .in-square-text-twt .container-text .time, .square .small--fifth-position .in-square-text-twt .container-text .time {
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 6.5px;
          letter-spacing: 0;
          line-height: 8px;
          height: 14px;
          width: 104px;
          background-color: #76787b;
          text-align: center;
          position: absolute;
          top: 181px;
          left: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1; }
        .square .small--first-position .in-square-text-twt .container-text .hashtag, .square .small--second-position .in-square-text-twt .container-text .hashtag, .square .small--third-position .in-square-text-twt .container-text .hashtag, .square .small--fourth-position .in-square-text-twt .container-text .hashtag, .square .small--fifth-position .in-square-text-twt .container-text .hashtag {
          color: #000;
          font-family: "Conv_BuenosAires-Black";
          font-size: 12px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 17px;
          height: 21px;
          width: 144px;
          background-color: #fff;
          text-align: center;
          position: absolute;
          top: 195px;
          left: 64px;
          padding-top: 2px;
          text-transform: uppercase;
          z-index: 1; }
        .square .small--first-position .in-square-text-twt .container-text .decoration_div, .square .small--second-position .in-square-text-twt .container-text .decoration_div, .square .small--third-position .in-square-text-twt .container-text .decoration_div, .square .small--fourth-position .in-square-text-twt .container-text .decoration_div, .square .small--fifth-position .in-square-text-twt .container-text .decoration_div {
          height: 28px;
          width: 173px;
          background-color: #c4c4c4;
          z-index: 0;
          position: absolute;
          left: 48px;
          top: 181px; }
      .square .small--first-position .in-square-text-twt .container-text.miskCollaborate, .square .small--second-position .in-square-text-twt .container-text.miskCollaborate, .square .small--third-position .in-square-text-twt .container-text.miskCollaborate, .square .small--fourth-position .in-square-text-twt .container-text.miskCollaborate, .square .small--fifth-position .in-square-text-twt .container-text.miskCollaborate {
        background-color: #9e75f7; }
      .square .small--first-position .in-square-text-twt .container-text.askMisk, .square .small--second-position .in-square-text-twt .container-text.askMisk, .square .small--third-position .in-square-text-twt .container-text.askMisk, .square .small--fourth-position .in-square-text-twt .container-text.askMisk, .square .small--fifth-position .in-square-text-twt .container-text.askMisk {
        background-color: #ffb500; }
      .square .small--first-position .in-square-text-twt .container-text.miskCity, .square .small--second-position .in-square-text-twt .container-text.miskCity, .square .small--third-position .in-square-text-twt .container-text.miskCity, .square .small--fourth-position .in-square-text-twt .container-text.miskCity, .square .small--fifth-position .in-square-text-twt .container-text.miskCity {
        background-color: #21b592; }
      .square .small--first-position .in-square-text-twt .container-text.default, .square .small--second-position .in-square-text-twt .container-text.default, .square .small--third-position .in-square-text-twt .container-text.default, .square .small--fourth-position .in-square-text-twt .container-text.default, .square .small--fifth-position .in-square-text-twt .container-text.default {
        background-color: #000; }
    .square .small--first-position .in-square-image-twt, .square .small--second-position .in-square-image-twt, .square .small--third-position .in-square-image-twt, .square .small--fourth-position .in-square-image-twt, .square .small--fifth-position .in-square-image-twt {
      height: 233px;
      width: 233px;
      position: relative; }
      .square .small--first-position .in-square-image-twt .back-image, .square .small--second-position .in-square-image-twt .back-image, .square .small--third-position .in-square-image-twt .back-image, .square .small--fourth-position .in-square-image-twt .back-image, .square .small--fifth-position .in-square-image-twt .back-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 0;
        width: 235px;
        height: 235px;
        object-fit: cover;
        object-position: center; }
      .square .small--first-position .in-square-image-twt .logo, .square .small--second-position .in-square-image-twt .logo, .square .small--third-position .in-square-image-twt .logo, .square .small--fourth-position .in-square-image-twt .logo, .square .small--fifth-position .in-square-image-twt .logo {
        position: absolute;
        left: 207px;
        top: 10px;
        z-index: 0; }
      .square .small--first-position .in-square-image-twt .content_image, .square .small--second-position .in-square-image-twt .content_image, .square .small--third-position .in-square-image-twt .content_image, .square .small--fourth-position .in-square-image-twt .content_image, .square .small--fifth-position .in-square-image-twt .content_image {
        position: absolute;
        left: 12px;
        top: 12px; }
        .square .small--first-position .in-square-image-twt .content_image .avatar, .square .small--second-position .in-square-image-twt .content_image .avatar, .square .small--third-position .in-square-image-twt .content_image .avatar, .square .small--fourth-position .in-square-image-twt .content_image .avatar, .square .small--fifth-position .in-square-image-twt .content_image .avatar {
          position: absolute;
          top: 166px;
          left: 0px;
          height: 44px;
          width: 50px;
          background-color: #ffffff; }
          .square .small--first-position .in-square-image-twt .content_image .avatar .avatar-img, .square .small--second-position .in-square-image-twt .content_image .avatar .avatar-img, .square .small--third-position .in-square-image-twt .content_image .avatar .avatar-img, .square .small--fourth-position .in-square-image-twt .content_image .avatar .avatar-img, .square .small--fifth-position .in-square-image-twt .content_image .avatar .avatar-img {
            position: absolute;
            left: 8px;
            top: 5px;
            height: 35px;
            width: 35px;
            z-index: 6;
            border-radius: 50%; }
        .square .small--first-position .in-square-image-twt .content_image .textname, .square .small--second-position .in-square-image-twt .content_image .textname, .square .small--third-position .in-square-image-twt .content_image .textname, .square .small--fourth-position .in-square-image-twt .content_image .textname, .square .small--fifth-position .in-square-image-twt .content_image .textname {
          color: #ffffff;
          font-family: "Conv_BuenosAires-Black";
          font-size: 9px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 13px;
          height: 16px;
          width: 104px;
          background-color: #000000;
          position: absolute;
          top: 165px;
          left: 50px;
          padding-top: 1px; }
        .square .small--first-position .in-square-image-twt .content_image .time, .square .small--second-position .in-square-image-twt .content_image .time, .square .small--third-position .in-square-image-twt .content_image .time, .square .small--fourth-position .in-square-image-twt .content_image .time, .square .small--fifth-position .in-square-image-twt .content_image .time {
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 6.5px;
          letter-spacing: 0;
          line-height: 8px;
          height: 14px;
          width: 104px;
          background-color: #76787b;
          text-align: center;
          position: absolute;
          top: 181px;
          left: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1; }
        .square .small--first-position .in-square-image-twt .content_image .hashtag, .square .small--second-position .in-square-image-twt .content_image .hashtag, .square .small--third-position .in-square-image-twt .content_image .hashtag, .square .small--fourth-position .in-square-image-twt .content_image .hashtag, .square .small--fifth-position .in-square-image-twt .content_image .hashtag {
          color: #000;
          font-family: "Conv_BuenosAires-Black";
          font-size: 12px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 17px;
          height: 21px;
          width: 144px;
          background-color: #fff;
          text-align: center;
          position: absolute;
          top: 195px;
          left: 64px;
          padding-top: 2px;
          text-transform: uppercase;
          z-index: 1; }
        .square .small--first-position .in-square-image-twt .content_image .decoration_div, .square .small--second-position .in-square-image-twt .content_image .decoration_div, .square .small--third-position .in-square-image-twt .content_image .decoration_div, .square .small--fourth-position .in-square-image-twt .content_image .decoration_div, .square .small--fifth-position .in-square-image-twt .content_image .decoration_div {
          height: 28px;
          width: 173px;
          background-color: #da1884;
          z-index: 0;
          position: absolute;
          left: 48px;
          top: 181px; }
        .square .small--first-position .in-square-image-twt .content_image .decoration_div.miskCollaborate, .square .small--second-position .in-square-image-twt .content_image .decoration_div.miskCollaborate, .square .small--third-position .in-square-image-twt .content_image .decoration_div.miskCollaborate, .square .small--fourth-position .in-square-image-twt .content_image .decoration_div.miskCollaborate, .square .small--fifth-position .in-square-image-twt .content_image .decoration_div.miskCollaborate {
          background-color: #9e75f7; }
        .square .small--first-position .in-square-image-twt .content_image .decoration_div.askMisk, .square .small--second-position .in-square-image-twt .content_image .decoration_div.askMisk, .square .small--third-position .in-square-image-twt .content_image .decoration_div.askMisk, .square .small--fourth-position .in-square-image-twt .content_image .decoration_div.askMisk, .square .small--fifth-position .in-square-image-twt .content_image .decoration_div.askMisk {
          background-color: #ffb500; }
        .square .small--first-position .in-square-image-twt .content_image .decoration_div.miskCity, .square .small--second-position .in-square-image-twt .content_image .decoration_div.miskCity, .square .small--third-position .in-square-image-twt .content_image .decoration_div.miskCity, .square .small--fourth-position .in-square-image-twt .content_image .decoration_div.miskCity, .square .small--fifth-position .in-square-image-twt .content_image .decoration_div.miskCity {
          background-color: #21b592; }
        .square .small--first-position .in-square-image-twt .content_image .decoration_div.default, .square .small--second-position .in-square-image-twt .content_image .decoration_div.default, .square .small--third-position .in-square-image-twt .content_image .decoration_div.default, .square .small--fourth-position .in-square-image-twt .content_image .decoration_div.default, .square .small--fifth-position .in-square-image-twt .content_image .decoration_div.default {
          background-color: #000; }
    .square .small--first-position .in-square-image-insta, .square .small--second-position .in-square-image-insta, .square .small--third-position .in-square-image-insta, .square .small--fourth-position .in-square-image-insta, .square .small--fifth-position .in-square-image-insta {
      height: 233px;
      width: 233px;
      position: relative; }
      .square .small--first-position .in-square-image-insta .back-image, .square .small--second-position .in-square-image-insta .back-image, .square .small--third-position .in-square-image-insta .back-image, .square .small--fourth-position .in-square-image-insta .back-image, .square .small--fifth-position .in-square-image-insta .back-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 0;
        width: 235px;
        height: 235px;
        object-fit: cover;
        object-position: center; }
      .square .small--first-position .in-square-image-insta .logo, .square .small--second-position .in-square-image-insta .logo, .square .small--third-position .in-square-image-insta .logo, .square .small--fourth-position .in-square-image-insta .logo, .square .small--fifth-position .in-square-image-insta .logo {
        position: absolute;
        left: 207px;
        top: 10px;
        z-index: 0; }
      .square .small--first-position .in-square-image-insta .content_image, .square .small--second-position .in-square-image-insta .content_image, .square .small--third-position .in-square-image-insta .content_image, .square .small--fourth-position .in-square-image-insta .content_image, .square .small--fifth-position .in-square-image-insta .content_image {
        position: absolute;
        left: 12px;
        top: 12px; }
        .square .small--first-position .in-square-image-insta .content_image .time, .square .small--second-position .in-square-image-insta .content_image .time, .square .small--third-position .in-square-image-insta .content_image .time, .square .small--fourth-position .in-square-image-insta .content_image .time, .square .small--fifth-position .in-square-image-insta .content_image .time {
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 6.5px;
          letter-spacing: 0;
          line-height: 8px;
          height: 14px;
          width: 104px;
          background-color: #76787b;
          text-align: center;
          position: absolute;
          top: 181px;
          left: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1; }
        .square .small--first-position .in-square-image-insta .content_image .hashtag, .square .small--second-position .in-square-image-insta .content_image .hashtag, .square .small--third-position .in-square-image-insta .content_image .hashtag, .square .small--fourth-position .in-square-image-insta .content_image .hashtag, .square .small--fifth-position .in-square-image-insta .content_image .hashtag {
          color: #000;
          font-family: "Conv_BuenosAires-Black";
          font-size: 12px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 17px;
          height: 21px;
          width: 144px;
          background-color: #fff;
          text-align: center;
          position: absolute;
          top: 195px;
          left: 64px;
          padding-top: 2px;
          text-transform: uppercase;
          z-index: 1; }
        .square .small--first-position .in-square-image-insta .content_image .decoration_div, .square .small--second-position .in-square-image-insta .content_image .decoration_div, .square .small--third-position .in-square-image-insta .content_image .decoration_div, .square .small--fourth-position .in-square-image-insta .content_image .decoration_div, .square .small--fifth-position .in-square-image-insta .content_image .decoration_div {
          height: 28px;
          width: 173px;
          background-color: #da1884;
          z-index: 0;
          position: absolute;
          left: 48px;
          top: 181px; }
        .square .small--first-position .in-square-image-insta .content_image .decoration_div.miskCollaborate, .square .small--second-position .in-square-image-insta .content_image .decoration_div.miskCollaborate, .square .small--third-position .in-square-image-insta .content_image .decoration_div.miskCollaborate, .square .small--fourth-position .in-square-image-insta .content_image .decoration_div.miskCollaborate, .square .small--fifth-position .in-square-image-insta .content_image .decoration_div.miskCollaborate {
          background-color: #9e75f7; }
        .square .small--first-position .in-square-image-insta .content_image .decoration_div.askMisk, .square .small--second-position .in-square-image-insta .content_image .decoration_div.askMisk, .square .small--third-position .in-square-image-insta .content_image .decoration_div.askMisk, .square .small--fourth-position .in-square-image-insta .content_image .decoration_div.askMisk, .square .small--fifth-position .in-square-image-insta .content_image .decoration_div.askMisk {
          background-color: #ffb500; }
        .square .small--first-position .in-square-image-insta .content_image .decoration_div.miskCity, .square .small--second-position .in-square-image-insta .content_image .decoration_div.miskCity, .square .small--third-position .in-square-image-insta .content_image .decoration_div.miskCity, .square .small--fourth-position .in-square-image-insta .content_image .decoration_div.miskCity, .square .small--fifth-position .in-square-image-insta .content_image .decoration_div.miskCity {
          background-color: #21b592; }
        .square .small--first-position .in-square-image-insta .content_image .decoration_div.default, .square .small--second-position .in-square-image-insta .content_image .decoration_div.default, .square .small--third-position .in-square-image-insta .content_image .decoration_div.default, .square .small--fourth-position .in-square-image-insta .content_image .decoration_div.default, .square .small--fifth-position .in-square-image-insta .content_image .decoration_div.default {
          background-color: #000; }
