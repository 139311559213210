html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* 10px = 1rem! */ }

body {
  font-family: "Domine", serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
  overflow: initial; }

*,
*:before,
*:after {
  box-sizing: inherit; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin;
  pointer-events: none; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; } }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
