.rectangle {
  position: relative;
  height: 470px;
  width: 705px;
  border: 1px solid #979797;
  overflow: hidden; }
  .rectangle .logo-twitter {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .rectangle .logo-instagram {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .rectangle .logo-fade-out {
    animation-name: slideDown;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out; }

@keyframes slideDown {
  0% {
    top: -446px;
    opacity: 1; }
  30%,
  70% {
    top: 0px;
    opacity: 1; }
  100% {
    top: 0px;
    opacity: 0; } }
  .rectangle--first-position {
    position: absolute;
    left: 705px;
    top: 470px;
    padding: 12px; }
  .rectangle--second-position {
    position: absolute;
    left: 2820px;
    top: 470px;
    padding: 12px; }
  .rectangle--first-position .content-item-logo,
  .rectangle--first-position .logo-fade-out, .rectangle--second-position .content-item-logo,
  .rectangle--second-position .logo-fade-out {
    height: 470px;
    width: 704px;
    padding: 12px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 7;
    left: 0px;
    top: -459px;
    background-color: #ffffff; }
    .rectangle--first-position .content-item-logo .item-logo,
    .rectangle--first-position .logo-fade-out .item-logo, .rectangle--second-position .content-item-logo .item-logo,
    .rectangle--second-position .logo-fade-out .item-logo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .rectangle--first-position .content-item-logo .item-logo.miskCollaborate,
      .rectangle--first-position .logo-fade-out .item-logo.miskCollaborate, .rectangle--second-position .content-item-logo .item-logo.miskCollaborate,
      .rectangle--second-position .logo-fade-out .item-logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle--first-position .content-item-logo .item-logo.askMisk,
      .rectangle--first-position .logo-fade-out .item-logo.askMisk, .rectangle--second-position .content-item-logo .item-logo.askMisk,
      .rectangle--second-position .logo-fade-out .item-logo.askMisk {
        background-color: #ffb500; }
      .rectangle--first-position .content-item-logo .item-logo.miskCity,
      .rectangle--first-position .logo-fade-out .item-logo.miskCity, .rectangle--second-position .content-item-logo .item-logo.miskCity,
      .rectangle--second-position .logo-fade-out .item-logo.miskCity {
        background-color: #21b592; }
      .rectangle--first-position .content-item-logo .item-logo.default,
      .rectangle--first-position .logo-fade-out .item-logo.default, .rectangle--second-position .content-item-logo .item-logo.default,
      .rectangle--second-position .logo-fade-out .item-logo.default {
        background-color: #000; }
      .rectangle--first-position .content-item-logo .item-logo img,
      .rectangle--first-position .logo-fade-out .item-logo img, .rectangle--second-position .content-item-logo .item-logo img,
      .rectangle--second-position .logo-fade-out .item-logo img {
        position: relative;
        width: 165px;
        object-fit: cover;
        object-position: center;
        z-index: 1; }
  .rectangle--first-position .content_video_twitter, .rectangle--second-position .content_video_twitter {
    width: 100%;
    height: 100%;
    position: relative; }
    .rectangle--first-position .content_video_twitter .video_post, .rectangle--second-position .content_video_twitter .video_post {
      width: 446px;
      height: 444px;
      position: relative;
      z-index: 0; }
      .rectangle--first-position .content_video_twitter .video_post .bg-video__content, .rectangle--second-position .content_video_twitter .video_post .bg-video__content {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover; }
    .rectangle--first-position .content_video_twitter .content_text, .rectangle--second-position .content_video_twitter .content_text {
      position: absolute;
      top: 276px;
      left: 385px;
      height: 50px;
      width: 265px;
      border-radius: 4px;
      background-color: #da1884;
      z-index: 1; }
      .rectangle--first-position .content_video_twitter .content_text .logo, .rectangle--second-position .content_video_twitter .content_text .logo {
        position: absolute;
        left: 237px;
        top: 10px;
        z-index: 0; }
    .rectangle--first-position .content_video_twitter .content_text.miskCollaborate, .rectangle--second-position .content_video_twitter .content_text.miskCollaborate {
      background-color: #9e75f7; }
    .rectangle--first-position .content_video_twitter .content_text.askMisk, .rectangle--second-position .content_video_twitter .content_text.askMisk {
      background-color: #ffb500; }
    .rectangle--first-position .content_video_twitter .content_text.miskCity, .rectangle--second-position .content_video_twitter .content_text.miskCity {
      background-color: #21b592; }
    .rectangle--first-position .content_video_twitter .content_text.default, .rectangle--second-position .content_video_twitter .content_text.default {
      background-color: #000; }
    .rectangle--first-position .content_video_twitter .content_information, .rectangle--second-position .content_video_twitter .content_information {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .rectangle--first-position .content_video_twitter .content_information .avatar, .rectangle--second-position .content_video_twitter .content_information .avatar {
        position: absolute;
        height: 70px;
        width: 70px;
        background-color: #ffffff;
        z-index: 1;
        top: 306px;
        left: 356px; }
        .rectangle--first-position .content_video_twitter .content_information .avatar .avatar-img, .rectangle--second-position .content_video_twitter .content_information .avatar .avatar-img {
          position: absolute;
          height: 50px;
          width: 50px;
          left: 10px;
          top: 10px;
          z-index: 6;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .rectangle--first-position .content_video_twitter .content_information .textname, .rectangle--second-position .content_video_twitter .content_information .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 20px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        padding-top: 4px;
        top: 306px;
        left: 426px;
        z-index: 1; }
      .rectangle--first-position .content_video_twitter .content_information .time, .rectangle--second-position .content_video_twitter .content_information .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 20px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 326px;
        left: 426px; }
      .rectangle--first-position .content_video_twitter .content_information .hashtag, .rectangle--second-position .content_video_twitter .content_information .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 27px;
        width: 175px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 6px;
        padding-left: 9px;
        z-index: 1;
        left: 462px;
        top: 358px;
        text-transform: uppercase; }
      .rectangle--first-position .content_video_twitter .content_information .decoration_div, .rectangle--second-position .content_video_twitter .content_information .decoration_div {
        height: 150px;
        width: 245px;
        border-radius: 0 4px 4px 0;
        background-color: #c4c4c4;
        z-index: 0;
        position: absolute;
        top: 226px;
        left: 420px; }
  .rectangle--first-position .content_image_twitter, .rectangle--second-position .content_image_twitter {
    width: 100%;
    height: 100%;
    position: relative; }
    .rectangle--first-position .content_image_twitter .content_image, .rectangle--second-position .content_image_twitter .content_image {
      width: 446px;
      height: 444px;
      position: relative;
      z-index: 0; }
      .rectangle--first-position .content_image_twitter .content_image .back-image, .rectangle--second-position .content_image_twitter .content_image .back-image {
        width: 100%;
        height: 100%;
        border-radius: 4px; }
    .rectangle--first-position .content_image_twitter .content_text, .rectangle--second-position .content_image_twitter .content_text {
      position: absolute;
      top: 86px;
      left: 370px;
      height: 240px;
      width: 300px;
      border-radius: 4px;
      background-color: #da1884;
      z-index: 1; }
      .rectangle--first-position .content_image_twitter .content_text p, .rectangle--second-position .content_image_twitter .content_text p {
        height: 147px;
        width: 234px;
        margin: 42px 35px 51px 31px;
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 23px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: left;
        white-space: normal;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden; }
      .rectangle--first-position .content_image_twitter .content_text p.textMin, .rectangle--second-position .content_image_twitter .content_text p.textMin {
        font-size: 23px; }
      .rectangle--first-position .content_image_twitter .content_text p.textMin100, .rectangle--second-position .content_image_twitter .content_text p.textMin100 {
        font-size: 19px; }
      .rectangle--first-position .content_image_twitter .content_text p.textMin200, .rectangle--second-position .content_image_twitter .content_text p.textMin200 {
        font-size: 16px;
        line-height: 20px; }
      .rectangle--first-position .content_image_twitter .content_text p.textMin300, .rectangle--second-position .content_image_twitter .content_text p.textMin300 {
        font-size: 12px;
        line-height: 16px; }
      .rectangle--first-position .content_image_twitter .content_text p.more300, .rectangle--second-position .content_image_twitter .content_text p.more300 {
        font-size: 16px; }
      .rectangle--first-position .content_image_twitter .content_text p.ltr-layout, .rectangle--second-position .content_image_twitter .content_text p.ltr-layout {
        text-align: left; }
      .rectangle--first-position .content_image_twitter .content_text p.rtl-layout, .rectangle--second-position .content_image_twitter .content_text p.rtl-layout {
        text-align: right; }
      .rectangle--first-position .content_image_twitter .content_text .logo, .rectangle--second-position .content_image_twitter .content_text .logo {
        position: absolute;
        left: 272px;
        top: 11px;
        z-index: 0; }
    .rectangle--first-position .content_image_twitter .content_text.miskCollaborate, .rectangle--second-position .content_image_twitter .content_text.miskCollaborate {
      background-color: #9e75f7; }
    .rectangle--first-position .content_image_twitter .content_text.askMisk, .rectangle--second-position .content_image_twitter .content_text.askMisk {
      background-color: #ffb500; }
    .rectangle--first-position .content_image_twitter .content_text.miskCity, .rectangle--second-position .content_image_twitter .content_text.miskCity {
      background-color: #21b592; }
    .rectangle--first-position .content_image_twitter .content_text.default, .rectangle--second-position .content_image_twitter .content_text.default {
      background-color: #000; }
    .rectangle--first-position .content_image_twitter .content_information, .rectangle--second-position .content_image_twitter .content_information {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .rectangle--first-position .content_image_twitter .content_information .avatar, .rectangle--second-position .content_image_twitter .content_information .avatar {
        position: absolute;
        height: 70px;
        width: 70px;
        background-color: #ffffff;
        z-index: 1;
        top: 306px;
        left: 370px; }
        .rectangle--first-position .content_image_twitter .content_information .avatar .avatar-img, .rectangle--second-position .content_image_twitter .content_information .avatar .avatar-img {
          position: absolute;
          height: 50px;
          width: 50px;
          left: 10px;
          top: 10px;
          z-index: 6;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .rectangle--first-position .content_image_twitter .content_information .textname, .rectangle--second-position .content_image_twitter .content_information .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 20px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        padding-top: 4px;
        top: 306px;
        left: 440px;
        z-index: 1; }
      .rectangle--first-position .content_image_twitter .content_information .time, .rectangle--second-position .content_image_twitter .content_information .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 20px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 326px;
        left: 440px; }
      .rectangle--first-position .content_image_twitter .content_information .hashtag, .rectangle--second-position .content_image_twitter .content_information .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 27px;
        width: 175px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 6px;
        padding-left: 9px;
        z-index: 1;
        left: 474px;
        top: 358px;
        text-transform: uppercase; }
      .rectangle--first-position .content_image_twitter .content_information .decoration_div, .rectangle--second-position .content_image_twitter .content_information .decoration_div {
        height: 63px;
        width: 245px;
        border-radius: 0 4px 4px 0;
        background-color: #c4c4c4;
        z-index: 0;
        position: absolute;
        top: 313px;
        left: 440px; }
  .rectangle--first-position .content_video_insta, .rectangle--second-position .content_video_insta {
    width: 100%;
    height: 100%;
    position: relative; }
    .rectangle--first-position .content_video_insta .video_post, .rectangle--second-position .content_video_insta .video_post {
      width: 446px;
      height: 444px;
      position: relative;
      z-index: 0; }
      .rectangle--first-position .content_video_insta .video_post .bg-video__content, .rectangle--second-position .content_video_insta .video_post .bg-video__content {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover; }
    .rectangle--first-position .content_video_insta .content_text, .rectangle--second-position .content_video_insta .content_text {
      position: absolute;
      top: 276px;
      left: 385px;
      height: 50px;
      width: 265px;
      border-radius: 4px;
      background-color: #da1884;
      z-index: 1; }
      .rectangle--first-position .content_video_insta .content_text .logo, .rectangle--second-position .content_video_insta .content_text .logo {
        position: absolute;
        left: 237px;
        top: 10px;
        z-index: 0; }
    .rectangle--first-position .content_video_insta .content_text.miskCollaborate, .rectangle--second-position .content_video_insta .content_text.miskCollaborate {
      background-color: #9e75f7; }
    .rectangle--first-position .content_video_insta .content_text.askMisk, .rectangle--second-position .content_video_insta .content_text.askMisk {
      background-color: #ffb500; }
    .rectangle--first-position .content_video_insta .content_text.miskCity, .rectangle--second-position .content_video_insta .content_text.miskCity {
      background-color: #21b592; }
    .rectangle--first-position .content_video_insta .content_text.default, .rectangle--second-position .content_video_insta .content_text.default {
      background-color: #000; }
    .rectangle--first-position .content_video_insta .content_information, .rectangle--second-position .content_video_insta .content_information {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .rectangle--first-position .content_video_insta .content_information .time, .rectangle--second-position .content_video_insta .content_information .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 20px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 326px;
        left: 426px; }
      .rectangle--first-position .content_video_insta .content_information .hashtag, .rectangle--second-position .content_video_insta .content_information .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 27px;
        width: 175px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 6px;
        padding-left: 9px;
        z-index: 1;
        left: 462px;
        top: 358px;
        text-transform: uppercase; }
      .rectangle--first-position .content_video_insta .content_information .decoration_div, .rectangle--second-position .content_video_insta .content_information .decoration_div {
        height: 150px;
        width: 245px;
        border-radius: 0 4px 4px 0;
        background-color: #c4c4c4;
        z-index: 0;
        position: absolute;
        top: 226px;
        left: 420px; }
  .rectangle--first-position .content_image_insta, .rectangle--second-position .content_image_insta {
    width: 100%;
    height: 100%;
    position: relative; }
    .rectangle--first-position .content_image_insta .content_image, .rectangle--second-position .content_image_insta .content_image {
      width: 446px;
      height: 444px;
      position: relative;
      z-index: 0; }
      .rectangle--first-position .content_image_insta .content_image .back-image, .rectangle--second-position .content_image_insta .content_image .back-image {
        width: 100%;
        height: 100%;
        border-radius: 4px; }
    .rectangle--first-position .content_image_insta .content_text, .rectangle--second-position .content_image_insta .content_text {
      position: absolute;
      top: 86px;
      left: 370px;
      height: 240px;
      width: 300px;
      border-radius: 4px;
      background-color: #da1884;
      z-index: 1; }
      .rectangle--first-position .content_image_insta .content_text p, .rectangle--second-position .content_image_insta .content_text p {
        height: 147px;
        width: 234px;
        margin: 42px 35px 51px 31px;
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 23px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: left;
        white-space: normal;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden; }
      .rectangle--first-position .content_image_insta .content_text p.textMin, .rectangle--second-position .content_image_insta .content_text p.textMin {
        font-size: 23px; }
      .rectangle--first-position .content_image_insta .content_text p.textMin100, .rectangle--second-position .content_image_insta .content_text p.textMin100 {
        font-size: 19px; }
      .rectangle--first-position .content_image_insta .content_text p.textMin200, .rectangle--second-position .content_image_insta .content_text p.textMin200 {
        font-size: 16px;
        line-height: 20px; }
      .rectangle--first-position .content_image_insta .content_text p.textMin300, .rectangle--second-position .content_image_insta .content_text p.textMin300 {
        font-size: 12px;
        line-height: 16px; }
      .rectangle--first-position .content_image_insta .content_text p.more300, .rectangle--second-position .content_image_insta .content_text p.more300 {
        font-size: 16px; }
      .rectangle--first-position .content_image_insta .content_text p.ltr-layout, .rectangle--second-position .content_image_insta .content_text p.ltr-layout {
        text-align: left; }
      .rectangle--first-position .content_image_insta .content_text p.rtl-layout, .rectangle--second-position .content_image_insta .content_text p.rtl-layout {
        text-align: right; }
      .rectangle--first-position .content_image_insta .content_text .logo, .rectangle--second-position .content_image_insta .content_text .logo {
        position: absolute;
        left: 272px;
        top: 11px;
        z-index: 0; }
    .rectangle--first-position .content_image_insta .content_text.miskCollaborate, .rectangle--second-position .content_image_insta .content_text.miskCollaborate {
      background-color: #9e75f7; }
    .rectangle--first-position .content_image_insta .content_text.askMisk, .rectangle--second-position .content_image_insta .content_text.askMisk {
      background-color: #ffb500; }
    .rectangle--first-position .content_image_insta .content_text.miskCity, .rectangle--second-position .content_image_insta .content_text.miskCity {
      background-color: #21b592; }
    .rectangle--first-position .content_image_insta .content_text.default, .rectangle--second-position .content_image_insta .content_text.default {
      background-color: #000; }
    .rectangle--first-position .content_image_insta .content_information, .rectangle--second-position .content_image_insta .content_information {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .rectangle--first-position .content_image_insta .content_information .time, .rectangle--second-position .content_image_insta .content_information .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 20px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 326px;
        left: 432px; }
      .rectangle--first-position .content_image_insta .content_information .hashtag, .rectangle--second-position .content_image_insta .content_information .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 27px;
        width: 175px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 6px;
        padding-left: 9px;
        z-index: 1;
        left: 474px;
        top: 358px;
        text-transform: uppercase; }
      .rectangle--first-position .content_image_insta .content_information .decoration_div, .rectangle--second-position .content_image_insta .content_information .decoration_div {
        height: 63px;
        width: 245px;
        border-radius: 4px;
        background-color: #c4c4c4;
        z-index: 0;
        position: absolute;
        top: 313px;
        left: 432px; }
