.rectangle-short {
  position: relative;
  height: 235px;
  width: 705px;
  border: 1px solid #979797; }
  .rectangle-short .logo-twitter {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .rectangle-short .logo-instagram {
    background-image: url("../../assets/images/twitter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .rectangle-short .logo-fade-out {
    animation-name: switchPost;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out; }

@keyframes switchPost {
  0% {
    top: -235px;
    opacity: 1; }
  30%,
  70% {
    top: 0px;
    opacity: 1; }
  100% {
    top: 0px;
    opacity: 0; } }
  .rectangle-short--first-position {
    position: absolute;
    left: 0;
    top: 940px;
    padding: 12px;
    overflow: hidden; }
    .rectangle-short--first-position .content-item-logo,
    .rectangle-short--first-position .logo-fade-out {
      height: 233px;
      width: 704px;
      padding: 12px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 7;
      top: -248px;
      left: 0;
      background-color: #ffffff; }
      .rectangle-short--first-position .content-item-logo .item-logo,
      .rectangle-short--first-position .logo-fade-out .item-logo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .rectangle-short--first-position .content-item-logo .item-logo.miskCollaborate,
        .rectangle-short--first-position .logo-fade-out .item-logo.miskCollaborate {
          background-color: #9e75f7; }
        .rectangle-short--first-position .content-item-logo .item-logo.askMisk,
        .rectangle-short--first-position .logo-fade-out .item-logo.askMisk {
          background-color: #ffb500; }
        .rectangle-short--first-position .content-item-logo .item-logo.miskCity,
        .rectangle-short--first-position .logo-fade-out .item-logo.miskCity {
          background-color: #21b592; }
        .rectangle-short--first-position .content-item-logo .item-logo.default,
        .rectangle-short--first-position .logo-fade-out .item-logo.default {
          background-color: #000; }
        .rectangle-short--first-position .content-item-logo .item-logo img,
        .rectangle-short--first-position .logo-fade-out .item-logo img {
          position: relative;
          width: 115px;
          object-fit: cover;
          object-position: center;
          z-index: 1; }
    .rectangle-short--first-position .content_text_twt {
      width: 100%;
      height: 100%; }
      .rectangle-short--first-position .content_text_twt .text_content {
        height: 100px;
        width: 668px;
        border-radius: 4px;
        background-color: #da1884;
        position: absolute;
        top: 53px;
        z-index: 2; }
        .rectangle-short--first-position .content_text_twt .text_content p {
          height: 49px;
          width: 620px;
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 22px;
          letter-spacing: 0;
          line-height: 22px;
          margin: 20px 42px;
          text-align: left;
          white-space: normal;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden; }
        .rectangle-short--first-position .content_text_twt .text_content p.textMin {
          font-size: 26px;
          line-height: 30px; }
        .rectangle-short--first-position .content_text_twt .text_content p.textMin100 {
          font-size: 23px;
          line-height: 25px; }
        .rectangle-short--first-position .content_text_twt .text_content p.textMin200 {
          font-size: 18px;
          line-height: 20px; }
        .rectangle-short--first-position .content_text_twt .text_content p.textMin300 {
          font-size: 14px;
          line-height: 16px; }
        .rectangle-short--first-position .content_text_twt .text_content p.more300 {
          font-size: 12px; }
        .rectangle-short--first-position .content_text_twt .text_content p.ltr-layout {
          text-align: left; }
        .rectangle-short--first-position .content_text_twt .text_content p.rtl-layout {
          text-align: right; }
      .rectangle-short--first-position .content_text_twt .text_content.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_text_twt .text_content.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_text_twt .text_content.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_text_twt .text_content.default {
        background-color: #000; }
      .rectangle-short--first-position .content_text_twt .content_logo {
        height: 175px;
        width: 415px;
        border-radius: 4px;
        background-color: #da1884;
        position: absolute;
        top: 24px;
        left: 266px;
        z-index: 1; }
        .rectangle-short--first-position .content_text_twt .content_logo .logo {
          position: absolute;
          top: 10px;
          left: 387px; }
      .rectangle-short--first-position .content_text_twt .content_logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_text_twt .content_logo.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_text_twt .content_logo.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_text_twt .content_logo.default {
        background-color: #000; }
      .rectangle-short--first-position .content_text_twt .avatar {
        height: 44px;
        width: 50px;
        background-color: #ffffff;
        position: absolute;
        left: 210px;
        top: 153px;
        z-index: 1; }
        .rectangle-short--first-position .content_text_twt .avatar .avatar-img {
          height: 35px;
          width: 35px;
          margin: 5px 10px 4px 5px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .rectangle-short--first-position .content_text_twt .triangle {
        position: absolute;
        top: 153px;
        border-top: 14px solid #da1884;
        border-left: 8px solid #fff;
        border-right: 10px solid #fff;
        left: 248px;
        width: 0;
        margin: 0;
        z-index: 1; }
      .rectangle-short--first-position .content_text_twt .triangle.miskCollaborate {
        border-top: 14px solid #9e75f7; }
      .rectangle-short--first-position .content_text_twt .triangle.askMisk {
        border-top: 14px solid #ffb500; }
      .rectangle-short--first-position .content_text_twt .triangle.miskCity {
        border-top: 14px solid #21b592; }
      .rectangle-short--first-position .content_text_twt .triangle.default {
        border-top: 14px solid #000; }
      .rectangle-short--first-position .content_text_twt .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 16px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        padding-top: 2px;
        top: 153px;
        left: 266px;
        z-index: 1; }
      .rectangle-short--first-position .content_text_twt .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 16px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 169px;
        left: 266px; }
      .rectangle-short--first-position .content_text_twt .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 26px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        padding-top: 4px;
        z-index: 1;
        left: 521px;
        top: 185px;
        text-transform: uppercase; }
      .rectangle-short--first-position .content_text_twt .decoration_div {
        height: 16px;
        width: 255px;
        background-color: #c4c4c4;
        z-index: 1;
        position: absolute;
        top: 185px;
        left: 266px; }
    .rectangle-short--first-position .content_image_text_twt {
      width: 100%;
      height: 100%;
      position: relative; }
      .rectangle-short--first-position .content_image_text_twt .text_content {
        height: 110px;
        width: 350px;
        background-color: #da1884;
        position: absolute;
        top: 42px;
        z-index: 2; }
        .rectangle-short--first-position .content_image_text_twt .text_content p {
          height: 88px;
          width: 310px;
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 22px;
          letter-spacing: 0;
          line-height: 22px;
          margin: 11px 25px;
          text-align: left;
          white-space: normal;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.textMin {
          font-size: 22px;
          line-height: 22px; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.textMin100 {
          font-size: 18px;
          line-height: 20px; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.textMin200 {
          font-size: 15px;
          line-height: 15px; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.textMin300 {
          font-size: 12px;
          line-height: 13px; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.more300 {
          font-size: 12px; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.ltr-layout {
          text-align: left; }
        .rectangle-short--first-position .content_image_text_twt .text_content p.rtl-layout {
          text-align: right; }
      .rectangle-short--first-position .content_image_text_twt .text_content.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_image_text_twt .text_content.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_image_text_twt .text_content.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_image_text_twt .text_content.default {
        background-color: #000; }
      .rectangle-short--first-position .content_image_text_twt .content_logo {
        height: 211px;
        width: 92px;
        border-radius: 0 4px 4px 0;
        background-color: #da1884;
        position: absolute;
        top: 0px;
        left: 589px;
        z-index: 1; }
        .rectangle-short--first-position .content_image_text_twt .content_logo .logo {
          position: absolute;
          top: 12px;
          left: 64px; }
      .rectangle-short--first-position .content_image_text_twt .content_logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_image_text_twt .content_logo.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_image_text_twt .content_logo.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_image_text_twt .content_logo.default {
        background-color: #000; }
      .rectangle-short--first-position .content_image_text_twt .content_image {
        height: 235px;
        width: 266px;
        position: relative;
        z-index: 1;
        position: absolute;
        left: 323px;
        top: -13px; }
        .rectangle-short--first-position .content_image_text_twt .content_image .back-image {
          width: 100%;
          height: 100%;
          border-radius: 4px; }
      .rectangle-short--first-position .content_image_text_twt .avatar {
        height: 46px;
        width: 46px;
        background-color: #ffffff;
        position: absolute;
        left: 175px;
        top: 152px;
        z-index: 1; }
        .rectangle-short--first-position .content_image_text_twt .avatar .avatar-img {
          height: 35px;
          width: 35px;
          margin: 6px 6px 5px 5px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .rectangle-short--first-position .content_image_text_twt .triangle {
        position: absolute;
        top: 151px;
        border-top: 14px solid #da1884;
        border-left: 8px solid transparent;
        border-right: 10px solid transparent;
        left: 226px;
        width: 0;
        margin: 0;
        z-index: 1; }
      .rectangle-short--first-position .content_image_text_twt .triangle.miskCollaborate {
        border-top: 14px solid #9e75f7; }
      .rectangle-short--first-position .content_image_text_twt .triangle.askMisk {
        border-top: 14px solid #ffb500; }
      .rectangle-short--first-position .content_image_text_twt .triangle.miskCity {
        border-top: 14px solid #21b592; }
      .rectangle-short--first-position .content_image_text_twt .triangle.default {
        border-top: 14px solid #000; }
      .rectangle-short--first-position .content_image_text_twt .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 16px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        padding-top: 2px;
        top: 152px;
        left: 247px;
        z-index: 1; }
      .rectangle-short--first-position .content_image_text_twt .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 16px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 168px;
        left: 247px; }
      .rectangle-short--first-position .content_image_text_twt .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 26px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        padding-top: 4px;
        z-index: 1;
        left: 525px;
        top: 173px;
        text-transform: uppercase; }
      .rectangle-short--first-position .content_image_text_twt .decoration_div {
        height: 195px;
        width: 255px;
        background-color: #c4c4c4;
        z-index: 1;
        position: absolute;
        top: 8px;
        left: 168px;
        z-index: 0; }
    .rectangle-short--first-position .content_image_text_insta {
      width: 100%;
      height: 100%;
      position: relative; }
      .rectangle-short--first-position .content_image_text_insta .text_content {
        height: 110px;
        width: 350px;
        background-color: #da1884;
        position: absolute;
        top: 42px;
        z-index: 2; }
        .rectangle-short--first-position .content_image_text_insta .text_content p {
          height: 88px;
          width: 310px;
          color: #ffffff;
          font-family: "Conv_BuenosAires-Regular";
          font-size: 22px;
          letter-spacing: 0;
          line-height: 22px;
          margin: 11px 25px;
          text-align: left;
          white-space: normal;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.textMin {
          font-size: 22px;
          line-height: 22px; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.textMin100 {
          font-size: 18px;
          line-height: 20px; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.textMin200 {
          font-size: 15px;
          line-height: 15px; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.textMin300 {
          font-size: 12px;
          line-height: 13px; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.more300 {
          font-size: 12px; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.ltr-layout {
          text-align: left; }
        .rectangle-short--first-position .content_image_text_insta .text_content p.rtl-layout {
          text-align: right; }
      .rectangle-short--first-position .content_image_text_insta .text_content.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_image_text_insta .text_content.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_image_text_insta .text_content.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_image_text_insta .text_content.default {
        background-color: #000; }
      .rectangle-short--first-position .content_image_text_insta .content_logo {
        height: 211px;
        width: 92px;
        border-radius: 0 4px 4px 0;
        background-color: #da1884;
        position: absolute;
        top: 0px;
        left: 589px;
        z-index: 1; }
        .rectangle-short--first-position .content_image_text_insta .content_logo .logo {
          position: absolute;
          top: 12px;
          left: 64px; }
      .rectangle-short--first-position .content_image_text_insta .content_logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_image_text_insta .content_logo.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_image_text_insta .content_logo.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_image_text_insta .content_logo.default {
        background-color: #fff; }
      .rectangle-short--first-position .content_image_text_insta .content_image {
        height: 235px;
        width: 266px;
        position: relative;
        z-index: 1;
        position: absolute;
        left: 323px;
        top: -13px; }
        .rectangle-short--first-position .content_image_text_insta .content_image .back-image {
          width: 100%;
          height: 100%; }
      .rectangle-short--first-position .content_image_text_insta .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 16px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 152px;
        left: 247px; }
      .rectangle-short--first-position .content_image_text_insta .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 26px;
        width: 144px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 4px;
        z-index: 1;
        left: 525px;
        top: 173px;
        text-transform: uppercase; }
      .rectangle-short--first-position .content_image_text_insta .decoration_div {
        height: 195px;
        width: 255px;
        background-color: #c4c4c4;
        z-index: 1;
        position: absolute;
        top: 8px;
        left: 168px;
        z-index: 0; }
    .rectangle-short--first-position .content_video_twitter {
      width: 100%;
      height: 100%;
      position: relative; }
      .rectangle-short--first-position .content_video_twitter .content_logo {
        height: 211px;
        width: 630px;
        border-radius: 4px;
        background-color: #da1884;
        position: absolute;
        top: 0px;
        left: 51px;
        z-index: 1; }
        .rectangle-short--first-position .content_video_twitter .content_logo .logo {
          position: absolute;
          top: 12px;
          left: 602px; }
      .rectangle-short--first-position .content_video_twitter .content_logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_video_twitter .content_logo.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_video_twitter .content_logo.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_video_twitter .content_logo.default {
        background-color: #fff; }
      .rectangle-short--first-position .content_video_twitter .bg-video {
        position: absolute;
        top: -12px;
        left: -12px;
        min-height: 235px;
        height: 120%;
        width: 120%;
        z-index: 0;
        overflow: hidden;
        filter: blur(8px);
        -webkit-filter: blur(8px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        .rectangle-short--first-position .content_video_twitter .bg-video__content {
          height: 100%;
          width: 100%;
          object-fit: cover; }
      .rectangle-short--first-position .content_video_twitter .video_post {
        height: 235px;
        width: 266px;
        z-index: 1;
        position: absolute;
        left: 323px;
        top: -13px; }
        .rectangle-short--first-position .content_video_twitter .video_post .bg-video__content {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .rectangle-short--first-position .content_video_twitter .avatar {
        height: 46px;
        width: 46px;
        background-color: #ffffff;
        position: absolute;
        left: 109px;
        top: 83px;
        z-index: 1; }
        .rectangle-short--first-position .content_video_twitter .avatar .avatar-img {
          height: 35px;
          width: 35px;
          margin: 6px 6px 5px 5px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .rectangle-short--first-position .content_video_twitter .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 16px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        padding-top: 2px;
        top: 83px;
        left: 155px;
        z-index: 1; }
      .rectangle-short--first-position .content_video_twitter .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 16px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        top: 99px;
        left: 155px; }
      .rectangle-short--first-position .content_video_twitter .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 26px;
        width: 144px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 4px;
        z-index: 1;
        left: 525px;
        top: 173px;
        text-transform: uppercase; }
    .rectangle-short--first-position .content_video_insta {
      width: 100%;
      height: 100%;
      position: relative; }
      .rectangle-short--first-position .content_video_insta .content_logo {
        height: 211px;
        width: 630px;
        border-radius: 4px;
        background-color: #da1884;
        position: absolute;
        top: 0px;
        left: 51px;
        z-index: 1; }
        .rectangle-short--first-position .content_video_insta .content_logo .logo {
          position: absolute;
          top: 12px;
          left: 602px; }
      .rectangle-short--first-position .content_video_insta .content_logo.miskCollaborate {
        background-color: #9e75f7; }
      .rectangle-short--first-position .content_video_insta .content_logo.askMisk {
        background-color: #ffb500; }
      .rectangle-short--first-position .content_video_insta .content_logo.miskCity {
        background-color: #21b592; }
      .rectangle-short--first-position .content_video_insta .content_logo.default {
        background-color: #fff; }
      .rectangle-short--first-position .content_video_insta .bg-video {
        position: absolute;
        top: -12px;
        left: -12px;
        min-height: 235px;
        height: 120%;
        width: 120%;
        z-index: 0;
        overflow: hidden;
        filter: blur(8px);
        -webkit-filter: blur(8px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        .rectangle-short--first-position .content_video_insta .bg-video__content {
          height: 100%;
          width: 100%;
          object-fit: cover; }
      .rectangle-short--first-position .content_video_insta .video_post {
        height: 235px;
        width: 266px;
        z-index: 1;
        position: absolute;
        left: 323px;
        top: -13px; }
        .rectangle-short--first-position .content_video_insta .video_post .bg-video__content {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .rectangle-short--first-position .content_video_insta .hashtag {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 26px;
        width: 144px;
        background-color: #000000;
        text-align: center;
        position: absolute;
        padding-top: 4px;
        z-index: 1;
        left: 525px;
        top: 173px;
        text-transform: uppercase; }
