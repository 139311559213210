/** Generated by FG **/
@font-face {
	font-family: 'Conv_29LTBukra-Regular';
	src: url('fonts/29LTBukra-Regular.eot');
	src: local('☺'), url('fonts/29LTBukra-Regular.woff') format('woff'), url('fonts/29LTBukra-Regular.ttf') format('truetype'), url('fonts/29LTBukra-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bukra-bold';
	src: url('fonts/29LTBukra-Bold.otf') format('otf');			 
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bukra-Regular';
	src: url('fonts/29LTBukra-Regular.otf') format('otf');			 
	font-weight: normal;
	font-style: normal;
}
