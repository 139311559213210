.squareBig .logo-twitter {
  background-image: url("../../assets/images/twitter.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.squareBig .logo-instagram {
  background-image: url("../../assets/images/twitter.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.squareBig .logo-fade-out {
  animation-name: switchSquareContent;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out; }

@keyframes switchSquareContent {
  0% {
    left: -446px;
    opacity: 1; }
  30%,
  70% {
    left: 0px;
    opacity: 1; }
  100% {
    left: 0px;
    opacity: 0; } }

.squareBig .big {
  position: relative;
  height: 470px;
  width: 470px;
  border: 1px solid #979797;
  overflow: hidden; }
  .squareBig .big img {
    max-width: 468px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center; }
  .squareBig .big--first-position {
    position: absolute;
    left: 235px;
    top: 235px;
    overflow: hidden; }
  .squareBig .big--second-position {
    position: absolute;
    left: 1880px;
    top: 705px;
    overflow: hidden; }
  .squareBig .big--first-position .content-item-logo,
  .squareBig .big--first-position .logo-fade-out, .squareBig .big--second-position .content-item-logo,
  .squareBig .big--second-position .logo-fade-out {
    height: 470px;
    width: 470px;
    padding: 12px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -470px;
    z-index: 7;
    background-color: #ffffff; }
    .squareBig .big--first-position .content-item-logo .item-logo,
    .squareBig .big--first-position .logo-fade-out .item-logo, .squareBig .big--second-position .content-item-logo .item-logo,
    .squareBig .big--second-position .logo-fade-out .item-logo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .squareBig .big--first-position .content-item-logo .item-logo.miskCollaborate,
      .squareBig .big--first-position .logo-fade-out .item-logo.miskCollaborate, .squareBig .big--second-position .content-item-logo .item-logo.miskCollaborate,
      .squareBig .big--second-position .logo-fade-out .item-logo.miskCollaborate {
        background-color: #9e75f7; }
      .squareBig .big--first-position .content-item-logo .item-logo.askMisk,
      .squareBig .big--first-position .logo-fade-out .item-logo.askMisk, .squareBig .big--second-position .content-item-logo .item-logo.askMisk,
      .squareBig .big--second-position .logo-fade-out .item-logo.askMisk {
        background-color: #ffb500; }
      .squareBig .big--first-position .content-item-logo .item-logo.miskCity,
      .squareBig .big--first-position .logo-fade-out .item-logo.miskCity, .squareBig .big--second-position .content-item-logo .item-logo.miskCity,
      .squareBig .big--second-position .logo-fade-out .item-logo.miskCity {
        background-color: #21b592; }
      .squareBig .big--first-position .content-item-logo .item-logo.default,
      .squareBig .big--first-position .logo-fade-out .item-logo.default, .squareBig .big--second-position .content-item-logo .item-logo.default,
      .squareBig .big--second-position .logo-fade-out .item-logo.default {
        background-color: #000; }
      .squareBig .big--first-position .content-item-logo .item-logo img,
      .squareBig .big--first-position .logo-fade-out .item-logo img, .squareBig .big--second-position .content-item-logo .item-logo img,
      .squareBig .big--second-position .logo-fade-out .item-logo img {
        position: relative;
        width: 165px;
        object-fit: cover;
        object-position: center;
        z-index: 1; }
  .squareBig .big--first-position .in-square-image-twt, .squareBig .big--second-position .in-square-image-twt {
    height: 468px;
    width: 468px;
    position: relative; }
    .squareBig .big--first-position .in-square-image-twt .back-image, .squareBig .big--second-position .in-square-image-twt .back-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .squareBig .big--first-position .in-square-image-twt .logo, .squareBig .big--second-position .in-square-image-twt .logo {
      position: absolute;
      left: 434px;
      top: 10px;
      z-index: 0; }
    .squareBig .big--first-position .in-square-image-twt .content_image, .squareBig .big--second-position .in-square-image-twt .content_image {
      position: absolute;
      left: 247px;
      top: 242px; }
      .squareBig .big--first-position .in-square-image-twt .content_image .avatar, .squareBig .big--second-position .in-square-image-twt .content_image .avatar {
        position: absolute;
        top: 166px;
        left: 0px;
        height: 44px;
        width: 50px;
        background-color: #ffffff; }
        .squareBig .big--first-position .in-square-image-twt .content_image .avatar .avatar-img, .squareBig .big--second-position .in-square-image-twt .content_image .avatar .avatar-img {
          position: absolute;
          left: 5px;
          top: 6px;
          height: 35px;
          width: 35px;
          z-index: 6;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .squareBig .big--first-position .in-square-image-twt .content_image .textname, .squareBig .big--second-position .in-square-image-twt .content_image .textname {
        color: #fff;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 16px;
        width: 104px;
        background-color: #000;
        position: absolute;
        top: 165px;
        left: 50px;
        padding-top: 1px; }
      .squareBig .big--first-position .in-square-image-twt .content_image .time, .squareBig .big--second-position .in-square-image-twt .content_image .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 14px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        top: 181px;
        left: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1; }
      .squareBig .big--first-position .in-square-image-twt .content_image .hashtag, .squareBig .big--second-position .in-square-image-twt .content_image .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 21px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        top: 195px;
        left: 64px;
        padding-top: 2px;
        text-transform: uppercase;
        z-index: 1; }
      .squareBig .big--first-position .in-square-image-twt .content_image .decoration_div, .squareBig .big--second-position .in-square-image-twt .content_image .decoration_div {
        height: 28px;
        width: 173px;
        background-color: #da1884;
        z-index: 0;
        position: absolute;
        left: 48px;
        top: 181px; }
      .squareBig .big--first-position .in-square-image-twt .content_image .decoration_div.miskCollaborate, .squareBig .big--second-position .in-square-image-twt .content_image .decoration_div.miskCollaborate {
        background-color: #9e75f7; }
      .squareBig .big--first-position .in-square-image-twt .content_image .decoration_div.askMisk, .squareBig .big--second-position .in-square-image-twt .content_image .decoration_div.askMisk {
        background-color: #ffb500; }
      .squareBig .big--first-position .in-square-image-twt .content_image .decoration_div.miskCity, .squareBig .big--second-position .in-square-image-twt .content_image .decoration_div.miskCity {
        background-color: #21b592; }
      .squareBig .big--first-position .in-square-image-twt .content_image .decoration_div.default, .squareBig .big--second-position .in-square-image-twt .content_image .decoration_div.default {
        background-color: #000; }
  .squareBig .big--first-position .in-square-image-insta, .squareBig .big--second-position .in-square-image-insta {
    height: 468px;
    width: 468px;
    position: relative; }
    .squareBig .big--first-position .in-square-image-insta .back-image, .squareBig .big--second-position .in-square-image-insta .back-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
      height: 100%;
      object-fit: cover; }
    .squareBig .big--first-position .in-square-image-insta .logo, .squareBig .big--second-position .in-square-image-insta .logo {
      position: absolute;
      left: 434px;
      top: 10px;
      z-index: 0; }
    .squareBig .big--first-position .in-square-image-insta .content_image, .squareBig .big--second-position .in-square-image-insta .content_image {
      position: absolute;
      left: 247px;
      top: 247px; }
      .squareBig .big--first-position .in-square-image-insta .content_image .time, .squareBig .big--second-position .in-square-image-insta .content_image .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 14px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        top: 181px;
        left: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1; }
      .squareBig .big--first-position .in-square-image-insta .content_image .hashtag, .squareBig .big--second-position .in-square-image-insta .content_image .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 21px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        top: 195px;
        left: 64px;
        padding-top: 2px;
        text-transform: uppercase;
        z-index: 1; }
      .squareBig .big--first-position .in-square-image-insta .content_image .decoration_div, .squareBig .big--second-position .in-square-image-insta .content_image .decoration_div {
        height: 28px;
        width: 173px;
        background-color: #da1884;
        z-index: 0;
        position: absolute;
        left: 48px;
        top: 181px; }
      .squareBig .big--first-position .in-square-image-insta .content_image .decoration_div.miskCollaborate, .squareBig .big--second-position .in-square-image-insta .content_image .decoration_div.miskCollaborate {
        background-color: #9e75f7; }
      .squareBig .big--first-position .in-square-image-insta .content_image .decoration_div.askMisk, .squareBig .big--second-position .in-square-image-insta .content_image .decoration_div.askMisk {
        background-color: #ffb500; }
      .squareBig .big--first-position .in-square-image-insta .content_image .decoration_div.miskCity, .squareBig .big--second-position .in-square-image-insta .content_image .decoration_div.miskCity {
        background-color: #21b592; }
      .squareBig .big--first-position .in-square-image-insta .content_image .decoration_div.default, .squareBig .big--second-position .in-square-image-insta .content_image .decoration_div.default {
        background-color: #000; }
  .squareBig .big--first-position .video_twitter, .squareBig .big--second-position .video_twitter {
    height: 480px;
    width: 480px;
    position: absolute;
    overflow: hidden;
    left: -6px;
    top: -6px; }
    .squareBig .big--first-position .video_twitter .bg-video, .squareBig .big--second-position .video_twitter .bg-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      overflow: hidden;
      filter: blur(4px);
      -webkit-filter: blur(8px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .squareBig .big--first-position .video_twitter .bg-video__content, .squareBig .big--second-position .video_twitter .bg-video__content {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .squareBig .big--first-position .video_twitter .video_post, .squareBig .big--second-position .video_twitter .video_post {
      position: relative;
      height: 290px;
      width: 290px; }
      .squareBig .big--first-position .video_twitter .video_post .bg-video__content, .squareBig .big--second-position .video_twitter .video_post .bg-video__content {
        width: 100%;
        position: absolute;
        top: 20px;
        left: 20px; }
    .squareBig .big--first-position .video_twitter .text_container, .squareBig .big--second-position .video_twitter .text_container {
      height: 93px;
      width: 459px;
      background-color: #da1884;
      position: absolute;
      top: 310px;
      left: 11px; }
      .squareBig .big--first-position .video_twitter .text_container p, .squareBig .big--second-position .video_twitter .text_container p {
        height: 71px;
        width: 386px;
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 21px;
        letter-spacing: 0;
        line-height: 22px;
        padding: 0;
        margin: 12px 47px 10px 26px;
        white-space: normal;
        word-break: break-word;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden; }
      .squareBig .big--first-position .video_twitter .text_container p.textMin, .squareBig .big--second-position .video_twitter .text_container p.textMin {
        font-size: 21px;
        line-height: 22px; }
      .squareBig .big--first-position .video_twitter .text_container p.textMin100, .squareBig .big--second-position .video_twitter .text_container p.textMin100 {
        font-size: 20px;
        line-height: 21px; }
      .squareBig .big--first-position .video_twitter .text_container p.textMin200, .squareBig .big--second-position .video_twitter .text_container p.textMin200 {
        font-size: 14px;
        line-height: 15px; }
      .squareBig .big--first-position .video_twitter .text_container p.textMin300, .squareBig .big--second-position .video_twitter .text_container p.textMin300 {
        font-size: 12px;
        line-height: 14px; }
      .squareBig .big--first-position .video_twitter .text_container p.more300, .squareBig .big--second-position .video_twitter .text_container p.more300 {
        font-size: 10px; }
      .squareBig .big--first-position .video_twitter .text_container p.ltr-layout, .squareBig .big--second-position .video_twitter .text_container p.ltr-layout {
        text-align: left; }
      .squareBig .big--first-position .video_twitter .text_container p.rtl-layout, .squareBig .big--second-position .video_twitter .text_container p.rtl-layout {
        text-align: right; }
      .squareBig .big--first-position .video_twitter .text_container .logo, .squareBig .big--second-position .video_twitter .text_container .logo {
        position: absolute;
        left: 429px;
        top: 11px;
        z-index: 1; }
      .squareBig .big--first-position .video_twitter .text_container .triangle, .squareBig .big--second-position .video_twitter .text_container .triangle {
        position: absolute;
        top: 93px;
        border-top: 14px solid #da1884;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: 216px;
        width: 0;
        margin: 0; }
      .squareBig .big--first-position .video_twitter .text_container .triangle.miskCollaborate, .squareBig .big--second-position .video_twitter .text_container .triangle.miskCollaborate {
        border-top: 14px solid #9e75f7; }
      .squareBig .big--first-position .video_twitter .text_container .triangle.askMisk, .squareBig .big--second-position .video_twitter .text_container .triangle.askMisk {
        border-top: 14px solid #ffb500; }
      .squareBig .big--first-position .video_twitter .text_container .triangle.miskCity, .squareBig .big--second-position .video_twitter .text_container .triangle.miskCity {
        border-top: 14px solid #21b592; }
      .squareBig .big--first-position .video_twitter .text_container .triangle.default, .squareBig .big--second-position .video_twitter .text_container .triangle.default {
        border-top: 14px solid #000; }
    .squareBig .big--first-position .video_twitter .text_container.miskCollaborate, .squareBig .big--second-position .video_twitter .text_container.miskCollaborate {
      background-color: #9e75f7; }
    .squareBig .big--first-position .video_twitter .text_container.askMisk, .squareBig .big--second-position .video_twitter .text_container.askMisk {
      background-color: #ffb500; }
    .squareBig .big--first-position .video_twitter .text_container.miskCity, .squareBig .big--second-position .video_twitter .text_container.miskCity {
      background-color: #21b592; }
    .squareBig .big--first-position .video_twitter .text_container.default, .squareBig .big--second-position .video_twitter .text_container.default {
      background-color: #000; }
    .squareBig .big--first-position .video_twitter .content_image, .squareBig .big--second-position .video_twitter .content_image {
      position: absolute;
      left: 247px;
      top: 238px; }
      .squareBig .big--first-position .video_twitter .content_image .avatar, .squareBig .big--second-position .video_twitter .content_image .avatar {
        position: absolute;
        top: 165px;
        left: 0px;
        height: 44px;
        width: 50px;
        background-color: #ffffff; }
        .squareBig .big--first-position .video_twitter .content_image .avatar .avatar-img, .squareBig .big--second-position .video_twitter .content_image .avatar .avatar-img {
          position: absolute;
          left: 5px;
          top: 6px;
          height: 35px;
          width: 35px;
          z-index: 6;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
      .squareBig .big--first-position .video_twitter .content_image .textname, .squareBig .big--second-position .video_twitter .content_image .textname {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 9px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 13px;
        height: 16px;
        width: 104px;
        background-color: #fff;
        position: absolute;
        top: 165px;
        left: 50px;
        padding-top: 1px; }
      .squareBig .big--first-position .video_twitter .content_image .time, .squareBig .big--second-position .video_twitter .content_image .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 14px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        top: 181px;
        left: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1; }
      .squareBig .big--first-position .video_twitter .content_image .hashtag, .squareBig .big--second-position .video_twitter .content_image .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 21px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        top: 195px;
        left: 64px;
        padding-top: 2px;
        text-transform: uppercase;
        z-index: 1; }
      .squareBig .big--first-position .video_twitter .content_image .decoration_div, .squareBig .big--second-position .video_twitter .content_image .decoration_div {
        height: 28px;
        width: 173px;
        background-color: #da1884;
        z-index: 0;
        position: absolute;
        left: 48px;
        top: 181px; }
      .squareBig .big--first-position .video_twitter .content_image .decoration_div.miskCollaborate, .squareBig .big--second-position .video_twitter .content_image .decoration_div.miskCollaborate {
        background-color: #9e75f7; }
      .squareBig .big--first-position .video_twitter .content_image .decoration_div.askMisk, .squareBig .big--second-position .video_twitter .content_image .decoration_div.askMisk {
        background-color: #ffb500; }
      .squareBig .big--first-position .video_twitter .content_image .decoration_div.miskCity, .squareBig .big--second-position .video_twitter .content_image .decoration_div.miskCity {
        background-color: #21b592; }
      .squareBig .big--first-position .video_twitter .content_image .decoration_div.default, .squareBig .big--second-position .video_twitter .content_image .decoration_div.default {
        background-color: #000; }
  .squareBig .big--first-position .video_insta, .squareBig .big--second-position .video_insta {
    height: 480px;
    width: 480px;
    position: absolute;
    overflow: hidden;
    left: -6px;
    top: -6px; }
    .squareBig .big--first-position .video_insta .bg-video, .squareBig .big--second-position .video_insta .bg-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      overflow: hidden;
      filter: blur(4px);
      -webkit-filter: blur(8px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .squareBig .big--first-position .video_insta .bg-video__content, .squareBig .big--second-position .video_insta .bg-video__content {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .squareBig .big--first-position .video_insta .video_post, .squareBig .big--second-position .video_insta .video_post {
      position: relative;
      height: 290px;
      width: 290px; }
      .squareBig .big--first-position .video_insta .video_post .bg-video__content, .squareBig .big--second-position .video_insta .video_post .bg-video__content {
        width: 100%;
        position: absolute;
        top: 20px;
        left: 20px; }
    .squareBig .big--first-position .video_insta .text_container, .squareBig .big--second-position .video_insta .text_container {
      height: 93px;
      width: 459px;
      background-color: #da1884;
      position: absolute;
      top: 310px;
      left: 11px; }
      .squareBig .big--first-position .video_insta .text_container p, .squareBig .big--second-position .video_insta .text_container p {
        height: 71px;
        width: 386px;
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 21px;
        letter-spacing: 0;
        line-height: 22px;
        padding: 0;
        margin: 12px 47px 10px 26px;
        white-space: normal;
        word-break: break-word;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden; }
      .squareBig .big--first-position .video_insta .text_container p.textMin, .squareBig .big--second-position .video_insta .text_container p.textMin {
        font-size: 21px;
        line-height: 22px; }
      .squareBig .big--first-position .video_insta .text_container p.textMin100, .squareBig .big--second-position .video_insta .text_container p.textMin100 {
        font-size: 20px;
        line-height: 21px; }
      .squareBig .big--first-position .video_insta .text_container p.textMin200, .squareBig .big--second-position .video_insta .text_container p.textMin200 {
        font-size: 15px;
        line-height: 16px; }
      .squareBig .big--first-position .video_insta .text_container p.textMin300, .squareBig .big--second-position .video_insta .text_container p.textMin300 {
        font-size: 12px;
        line-height: 14px; }
      .squareBig .big--first-position .video_insta .text_container p.more300, .squareBig .big--second-position .video_insta .text_container p.more300 {
        font-size: 10px; }
      .squareBig .big--first-position .video_insta .text_container p.ltr-layout, .squareBig .big--second-position .video_insta .text_container p.ltr-layout {
        text-align: left; }
      .squareBig .big--first-position .video_insta .text_container p.rtl-layout, .squareBig .big--second-position .video_insta .text_container p.rtl-layout {
        text-align: right; }
      .squareBig .big--first-position .video_insta .text_container .logo, .squareBig .big--second-position .video_insta .text_container .logo {
        position: absolute;
        left: 429px;
        top: 11px;
        z-index: 1; }
    .squareBig .big--first-position .video_insta .text_container.miskCollaborate, .squareBig .big--second-position .video_insta .text_container.miskCollaborate {
      background-color: #9e75f7; }
    .squareBig .big--first-position .video_insta .text_container.askMisk, .squareBig .big--second-position .video_insta .text_container.askMisk {
      background-color: #ffb500; }
    .squareBig .big--first-position .video_insta .text_container.miskCity, .squareBig .big--second-position .video_insta .text_container.miskCity {
      background-color: #21b592; }
    .squareBig .big--first-position .video_insta .text_container.default, .squareBig .big--second-position .video_insta .text_container.default {
      background-color: #000; }
    .squareBig .big--first-position .video_insta .content_image, .squareBig .big--second-position .video_insta .content_image {
      position: absolute;
      left: 247px;
      top: 238px; }
      .squareBig .big--first-position .video_insta .content_image .time, .squareBig .big--second-position .video_insta .content_image .time {
        color: #ffffff;
        font-family: "Conv_BuenosAires-Regular";
        font-size: 6.5px;
        letter-spacing: 0;
        line-height: 8px;
        height: 14px;
        width: 104px;
        background-color: #76787b;
        text-align: center;
        position: absolute;
        top: 181px;
        left: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1; }
      .squareBig .big--first-position .video_insta .content_image .hashtag, .squareBig .big--second-position .video_insta .content_image .hashtag {
        color: #000;
        font-family: "Conv_BuenosAires-Black";
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        height: 21px;
        width: 144px;
        background-color: #fff;
        text-align: center;
        position: absolute;
        top: 195px;
        left: 64px;
        padding-top: 2px;
        text-transform: uppercase;
        z-index: 1; }
      .squareBig .big--first-position .video_insta .content_image .decoration_div, .squareBig .big--second-position .video_insta .content_image .decoration_div {
        height: 28px;
        width: 173px;
        background-color: #da1884;
        z-index: 0;
        position: absolute;
        left: 48px;
        top: 181px; }
      .squareBig .big--first-position .video_insta .content_image .decoration_div.miskCollaborate, .squareBig .big--second-position .video_insta .content_image .decoration_div.miskCollaborate {
        background-color: #9e75f7; }
      .squareBig .big--first-position .video_insta .content_image .decoration_div.askMisk, .squareBig .big--second-position .video_insta .content_image .decoration_div.askMisk {
        background-color: #ffb500; }
      .squareBig .big--first-position .video_insta .content_image .decoration_div.miskCity, .squareBig .big--second-position .video_insta .content_image .decoration_div.miskCity {
        background-color: #21b592; }
      .squareBig .big--first-position .video_insta .content_image .decoration_div.default, .squareBig .big--second-position .video_insta .content_image .decoration_div.default {
        background-color: #000; }
